import '../style/main.scss';

import React, { Component } from 'react';
import { getLanguage, setPageLanguageUsingSettings } from '../utils/language';

import Footer from './global/footer';
import PrimaryHeader from './global/primaryHeader';
import PropTypes from 'prop-types';
import SideMenu from './global/sideMenu';
import ieVersion from '../utils/ieVersion';
import translations from '../utils/translations';

const getScrollAmount = () => {
  return window.pageYOffset || document.documentElement.scrollTop;
};

class TemplateWrapper extends Component {
  mounted = false;
  constructor(props) {
    super(props);
    this.state = {
      scrollHidden:
        props.location.pathname === '' || props.location.pathname === '/'
          ? true
          : false,
      scrollAmount: 0,
      hideToggle: true,
      // showIntro: true,
      showIntro: false,
      headerShadow: false,
      isIE: ieVersion() !== 0,
      lang: 'en',
      pageData: null,
      scrollY: 0,
      megaMenuOpen: false,
    };
    this.scrollDirection = 'down';
    this.prevScrollDirection = 'down';
    this.amountInDirection = 0;
    if (typeof window !== 'undefined') {
      this.scrollAmount = getScrollAmount();
    } else {
      this.scrollAmount = 0;
    }

    setPageLanguageUsingSettings();
    getLanguage().then(lang => {
      translations.setLanguage(lang);
      this.setState({ lang: lang });
    });
  }

  componentDidMount() {
    this.mounted = true;
    let newState = {};
    if (this.props.location.hash.indexOf('#region') === -1) {
      newState['scrollHidden'] = false;
      ///this.setState({ scrollHidden: false });
    }
    window.addEventListener('scroll', this.handleScroll);

    if (ieVersion() !== 0) {
      document.querySelector('body').className = 'isIE';
    }
    if (this.props.location.pathname !== '/') {
      newState['showIntro'] = false;
      //this.setState({ showIntro: false });
    }
    //Remove menu when "Back" button is clicked
    window.addEventListener('popstate', this.removeMenuDelay);
    translations.setLanguageSource('en', this.props.data.translations);
    translations.setLanguageSource('es', this.props.data.translationsEs);
    this.setState(newState)
  }

  componentWillReceiveProps(nextProps) {
    //Always show header on route change
    if (this.props.location.pathname !== nextProps.location.pathname) {
      if (nextProps.location.hash.indexOf('#region') === -1) {
        this.setState({ scrollHidden: false });
      }
      this.setState({ pageData: false });
    }
  }

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('popstate', this.removeMenuDelay);
  }

  onIntroComplete = () => {
    this.setState({ showIntro: false });
  };
  hideHeader = () => {
    this.setState(state => ({
      scrollHidden: true,
      headerShadow: false,
      megaMenuOpen: false,
    }));
  };
  showHeader = () => {
    this.setState(state => ({
      scrollHidden: false,
    }));
  };
  toggleMenu = param => {
    document.body.style.overflow = this.state.hideToggle ? 'hidden' : 'auto';
    this.setState({ hideToggle: !this.state.hideToggle });
  };
  onToggleMegaMenu = () => {
    this.setState(prev => {
      const newState = !prev.megaMenuOpen;
      document.body.style.overflow = newState ? 'hidden' : 'auto';
      
      return {
        megaMenuOpen: newState
      };
    });
  }
  removeMenu = param => {
    document.body.style.overflow = 'auto';
    if (this.mounted) this.setState({ hideToggle: true });
  };
  removeMenuDelay = () => {
    setTimeout(this.removeMenu, 350);
  };
  handleScroll = e => {
    if (this.props.location.hash.indexOf('#region') !== -1) {
      return;
    }

    // no scroll handling on bomb blast
    const newScrollAmount = getScrollAmount();
    this.prevScrollDirection = this.scrollDirection;
    this.scrollDirection = newScrollAmount > this.scrollAmount ? 'down' : 'up';
    const scrollDif = newScrollAmount - this.scrollAmount;

    if (this.prevScrollDirection === this.scrollDirection) {
      this.amountInDirection += scrollDif;
    } else {
      this.amountInDirection = 0;
    }

    if (newScrollAmount < 100 && this.state.headerShadow) {
      this.setState({ headerShadow: false });
    }
    if (this.props.location.pathname.indexOf('bomb-blast') !== -1) {
      return;
    }

    if (newScrollAmount <= 100 && this.state.scrollHidden) {
      // if (this.props.location.hash)
      this.showHeader();
      return;
    }

    if (
      newScrollAmount + window.innerHeight >=
      document.body.scrollHeight - 250
    ) {
      this.setState({ headerShadow: true });
      this.showHeader();
      return;
    }

    if (
      newScrollAmount > this.scrollAmount &&
      this.amountInDirection > 30 &&
      !this.state.scrollHidden &&
      newScrollAmount > 100
    ) {
      this.hideHeader();
    } else if (
      newScrollAmount < this.scrollAmount &&
      this.amountInDirection < -30 &&
      this.state.scrollHidden
    ) {
      this.setState({ headerShadow: true });
      this.showHeader();
    }

    this.scrollAmount = newScrollAmount;
    this.setState({ scrollY: getScrollAmount() });
  };

  //format data in more friendly way for components
  extractData(data) {
    let formattedData = [];
    if (typeof data['edges'] !== 'undefined') {
      formattedData = data['edges'].map(edge => edge.node);
    }
    return formattedData[0];
  };
  handlePageDataChanged = (pageData) => {
    this.setState({ pageData: pageData });
  }

  render() {
    const noFooter =
      this.props.location.pathname.indexOf('interactive') !== -1 ||
      this.props.location.pathname.indexOf('climateTrends') !== -1;
    const headerData = (this.state.lang === 'es' ? this.props.data.headerEs : this.props.data.header);
    const pages = (this.state.lang === 'es' ? this.props.data.pagesEs.edges : this.props.data.pages.edges);
    const footer = (this.state.lang === 'es' ? this.props.data.footerEs : this.props.data.footer);
    const blog = (this.state.lang === 'es') ? this.props.data.blogES : this.props.data.blog;
    const isHomepage = (this.props.location.pathname === '' || this.props.location.pathname === '/' || this.props.location.pathname === '/es/');
    const footerStyle = (isHomepage) ? 'homepage-footer' : '';
    return (
      <div
        style={{ height: '100%' }}
        className={`${this.state.isIE ? 'isIE' : ''}`}
      >
        <PrimaryHeader
          key={`header`}
          hide={this.state.scrollHidden}
          isHidden={this.state.hideToggle}
          menuClick={this.toggleMenu}
          removeMenuClick={this.removeMenuDelay}
          activePath={this.props.location.pathname}
          headerData={this.extractData(headerData)}
          headerShadow={this.state.headerShadow}
          headerChallenges={{ 'edges': [] }}
          isIE={this.state.isIE}
          pageData={this.state.pageData}
          blogData={blog}
          scrollY={this.state.scrollY}
          onToggleMegaMenu={this.onToggleMegaMenu}
          megaMenuOpen={this.state.megaMenuOpen}
          megaMenuData={this.props.data.megaMenu}
          menuSocial={footer.edges[0].node.menu_social}
        />
        <main
          className={`sitewrap ${this.state.hideToggle ? '' : 'overlay-blur'} `}
        >
          {this.props.children}
        </main>
        {!noFooter && <Footer isExtended={true} blogData={blog} {...footer} />}
      </div>
    );
  }
}

export default TemplateWrapper;
