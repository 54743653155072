// extracted by mini-css-extract-plugin
export var padding = "tagged-module--padding--V+-6D";
export var loading = "tagged-module--loading--lii3y";
export var whiteBack = "tagged-module--whiteBack--L5ri4";
export var wrapper = "tagged-module--wrapper--CZIho";
export var header = "tagged-module--header--m0y1t";
export var content = "tagged-module--content--QDeHM";
export var articles = "tagged-module--articles--Hwgod";
export var latest = "tagged-module--latest--LO8nb";
export var center = "tagged-module--center--PmnOP";
export var slideUp = "tagged-module--slideUp--E+1Ge";
export var slideUpLarge = "tagged-module--slideUpLarge--SnCc9";