import { useStaticQuery, graphql } from 'gatsby';
import React from 'react';
import TemplateWrapper from './template-wrapper';

const TemplateWrapperData = (props) => {
	const data = useStaticQuery(
		graphql`
		query indexDefaultContent {
		  ...footerFields
		  footerEs: allItemsJson(filter: {jsonId: {eq: "es-11"}}) {
			edges {
			  node {
				footer {
				 address
				  link {
					url
					title
				  }
				  about_link {
					url
					title
				  }
				  privacy_policy {
					url
					title
				  }
				  disclaimer {
					url
					title
				  }
				  terms_of_use {
					url
					title
				  }
				  phone_number
				  summary
				}
				menu_social {
				 title
				  menu_name
				  weight
				  url
				  class
				  target
				}
			  }
			}
		  }
		  ...headerFields
		  headerEs: allItemsJson(filter: {jsonId: {eq: "es-11"}}) {
			edges {
			  node {
				id
				title
				subtitle
				short_description
				paragraphs {
				  id
				  title
				  path
				  type
				  menu_items {
					label
					menu_item {
					  id
					  path
					  title
					  image {
						sizes {
						  square_sm
						  square_md
						  near_square_lg
						  near_square_md
						  original
						} 
						alt_text
						title
					  }
					  archive {
						id
						title
						path
					  }
					  core_curriculum {
					   id
					   title
					   path
					  }
					}
				  }
				  link {
					url
					title
				  }
				  core_curriculum {
					id
					title
					path
				  }
				}
				menu_footer {
				  title
				  menu_name
				  weight
				  url
				}
				menu_social {
				  title
				  menu_name
				  weight
				  url
				  class
				  target
				}
			  }
			}
		  }
	  
		  pages: allItemsJson(filter: { content_type: { eq: "page" }, jsonId: {regex: "/^en-/"} }) {
			edges {
			  node {
				title
				content_type
				path
			  }
			}
		  }
		  pagesEs: allItemsJson(filter: { content_type: { eq: "page" }, jsonId: {regex: "/^es-/"} }) {
			edges {
			  node {
				title
				content_type
				path
			  }
			}
		  }
		  blog: allItemsJson(filter: { jsonId: { eq: "en-122" } }) {
			edges {
			  node {
				title
				content_type
				path
			  }
			}
		  }
		  blogES: allItemsJson(filter: { jsonId: { eq: "es-122" } }) {
			edges {
			  node {
				title
				content_type
				path
			  }
			}
		  }
		  translations:  allItemsJson(filter: {jsonId: {eq: "en-11"}}) {
			edges {
			  node {
			   translations {
				  id
				  key
				  value
				}
			  }
			}
		  }
		  translationsEs:  allItemsJson(filter: {jsonId: {eq: "es-11"}}) {
			edges {
			  node {
			   translations {
				  id
				  key
				  value
				}
			  }
			}
		  }
		  megaMenu: allItemsJson(filter: {content_type: {eq: "mega_menu"}}) {
			edges {
			  node {
				id
				jsonId
				content_type
				about {
				  link
				  title
				}
				our_team {
				  link
				  title
				}
				projects {
				  link
				  title
				}
				newsletter {
				  link
				  title
				}
				latest_stories {
				  uri
				  title
				}
				magazine {
				  link
				  title
				}
				educational_resources {
				  uri
				  title
				  image_url
				  image_alt
				}
				topics {
					display
					link
					langcode
				}
				translated {
				  stories_and_resources
				  educational_resources
				  latest_stories
				  magazine
				}
				lang
			  }
			}
		  }
		}`
	);

	return <TemplateWrapper location={props.location} data={data}>{props.children}</TemplateWrapper>
};

export default TemplateWrapperData;
