import React, { Component } from 'react';
import * as style from '../magazine/smallVerticleArticleSummary.module.scss';
import LazyImage from '../ui/lazyImage';
import translations from '../../utils/translations';

class SmallVerticleArticleSummary extends Component {
	render() {
		const {
			article,
		} = this.props;
		const imageSrc = (article.image.sizes.near_square_md) ? article.image.sizes.near_square_md : article.image.sizes.original;
		const authors = (article.authors) ? article.authors.map((author) => author.name).join(' / ') : null;
		
		return (
			<div className={style.articleSummary}>
				<div>
					<a href={article.path}>
						<LazyImage
							src={imageSrc}
							alt={article.image.alt_text	}
							hasRevealer={false}
							revealed={true}
							width={670}
							height={586}
							fitSize={true}
						/>
					</a>
				</div>
				<div className={style.articleDetails}>
					{article.archive && (
						<div className={style.category}><a href={article.archive.path}>{article.archive.title}</a></div>
					)}
					<div className={style.title}><a href={article.path}>{article.title}&nbsp;<span className={style.angle}>&#8250;</span></a></div>
					{!(authors === null || authors === '') &&
						<div className={style.author}>{translations.t('by')} {authors}</div>
					}
				</div>
			</div>
		)
	}
}

export default SmallVerticleArticleSummary;
