import React, { Component } from 'react';
import HeadTags from '../components/global/headTags';
import SmallVerticleArticleSummary from '../components/magazine/smallVerticleArticleSummary';
import * as style from './tagged.module.scss';
import Loading from '../components/global/loading';
import TemplateWrapperData from '../components/template-wrapper-data';
import { getLanguage } from '../utils/language';
import InlineMailChimpForm from '../components/magazine/inlineMailChimpForm';
import Button from '../components/ui/button';
import translations from '../utils/translations';

const INITIAL_LOAD_COUNT = 15;
const LOAD_COUNT = 6;

class Tagged extends Component {
	constructor(props) {
		super(props);
		this.loadArticles = this.loadArticles.bind(this);
	}
	state = {
		pageIndex: 0,
		content: [],
		hasMore: true,
		hasLoaded: false,
		loading: true,
	}

	componentDidMount() {
		this.loadArticles();
	}

	loadNextSet = () => {
		this.setState({
			loading: true,
		});
		this.loadArticles();
	}

	async loadArticles() {
		const lang = await getLanguage();
		const loadCount = this.state.content.length > 0 ? LOAD_COUNT : INITIAL_LOAD_COUNT;
		const endpoint = process.env.GATSBY_API_HOST ? process.env.GATSBY_API_HOST : 'cms.outrider.org';
		const result = await fetch(`${endpoint}/api/get-taxonomy-content?start=${this.state.pageIndex}&category=${this.props.pageContext.category}&lang=${lang}&limit=${loadCount}`);

		if (result && result.status === 200) {
			const response = await result.json();

			this.setState(prev => {
				return {
					content: prev.content.concat(response.articles),
					hasMore: response.hasMore,
					pageIndex: prev.pageIndex + response.articles.length,
					hasLoaded: true,
					loading: false,
				};
			});
		}
	}

	render() {
		const upperArticles = this.state.content.slice(0, 6).map((a, index) => {
			return <SmallVerticleArticleSummary key={index} article={a} />;
		});

		const lowerArticles = this.state.content.slice(6).map((a, index) => {
			return <SmallVerticleArticleSummary key={index} article={a} />;
		});

		let title = '';
		if (this.props.pageContext.category) {
			title = this.props.pageContext.category;
			title = title.charAt(0).toUpperCase() + title.slice(1);
		}

		return (
			<TemplateWrapperData location={this.props.location}>
				<HeadTags
					title="Topics"
					description="Article Topics"
					location={this.props.location}
				/>
				<div className={style.header}>
					<h3>{title}</h3>
				</div>
				<div className={`${style.wrapper} ${style.padding}`}>
					<div className={style.content}>
						{this.state.loading && <Loading align="center" style={{margin: 40}} />}
						<div className={style.articles}>
							{upperArticles}
						</div>
					</div>
				</div>
				<div className={style.latest}>
					<InlineMailChimpForm />
				</div>
				<div className={`${style.whiteBack} ${style.padding}`}>
					<div className={style.articles}>
						{lowerArticles}
					</div>
					{this.state.loading && <Loading align="center" style={{margin: 40}} />}
					{this.state.hasMore
						&& <div className={style.center}>
							<Button text={translations.t('View More')} color="orange" handleClick={this.loadNextSet} />
						</div>
					}
				</div>
			</TemplateWrapperData>
		);
	}
}

export default Tagged;